<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionMobile' : 'section'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 5"  class="textSection">
        <p class="textSection1 textBlue" v-html="$t('textSectionLeft1')"></p>
        <p class="textSection1 textGrey" v-html="$t('textSectionLeft2')"></p>
        <p class="textSection1 textBlue" v-html="$t('textSectionLeft3')"></p>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 5" class="textSection">
        <p class="textSection1 textGrey" v-html="$t('textSectionRight1')"></p>
        <strong><p class="textSection1 textBlue" v-html="$t('textSectionRight2')"></p></strong>

        <strong><span class="textSection1 textBlue" v-html="$t('module') + ' 1 : '"></span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight3')"></span> <br>

        <strong><span class="textSection1 textBlue" v-html="$t('module')  + ' 2 : '"></span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight4')"></span> <br>

        <strong><span class="textSection1 textBlue" v-html="$t('module') + ' 3 : '"></span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight5')"></span> <br>

        <strong><span class="textSection1 textBlue" v-html="$t('module')  + ' 4 : '"></span></strong>
        <span class="textSection1 textGrey" v-html="$t('textSectionRight6')"></span> <br>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
    </v-row>
    <v-row v-bind:justify="mobile ? 'center' : 'start'" v-bind:class="mobile ? 'sectionMobile' : 'section'" style="padding-bottom: 40px;" no-gutters>
      <v-btn v-bind:class="mobile ? 'contactUsBtnMobile' : 'contactUsBtn'"
             rounded
             height="65px"
             dark
             v-html="$t('contactUs')"
             @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
             color="#57939E">
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'WellBeingWorkshopComp',
  props: ['mobile'],
  data: () => ({}),
}
</script>

<i18n>
{
  "en": {
    "mainTitle": "Well-being workshop : take care of yourself",
    "textSectionLeft1": "The difference between our aspirations and the successes we achieve builds self-esteem.",
    "textSectionLeft2": "Discussing, sharing, creating links with others, are moments that maintain good self-esteem. <br> Positive self-esteem is a defense mechanism. A person who has rather good self-esteem has more ease in facing the challenges of life.",
    "textSectionLeft3": "The objective of this workshop is to strengthen a positive image of oneself in order to adopt favorable attitudes, particularly for one's health and well-being.",
    "textSectionRight1": "In a benevolent and playful spirit, an image consultant accompanies you to give you advice to enhance your face and your figure, help you to be attentive to your needs and encourage you to assert yourself. <br> These sessions are also an opportunity to decipher health recommendations and discuss the concept of aging well.",
    "textSectionRight2": "The « well-being: taking care of yourself »workshop consists of 4 modules:",
    "textSectionRight3": "Self-expression and knowledge of others.",
    "textSectionRight4": "Self-esteem, self-confidence and assertiveness.",
    "textSectionRight5": "Non-verbal communication and active aging well.",
    "textSectionRight6": "Colorimetry and enhancement of the face and silhouette.",
    "module": "Module ",
    "contactUs": "Contact us"
  },
  "fr": {
    "mainTitle": "Atelier bien être : prendre soin de soi",
    "textSectionLeft1": "La différence entre nos aspirations et les succès que nous parvenons à accomplir construit l’estime de soi.",
    "textSectionLeft2": "Echanger, partager, créer du lien avec les autres, sont des moments qui entretiennent une bonne estime de soi. <br> L’estime de soi positive est un mécanisme de défense. Une personne qui a plutôt une bonne estime d’elle-même a plus de facilité à affronter les défis de la vie.",
    "textSectionLeft3": "Cet atelier a pour objectif de renforcer une image positive de soi-même afin d’adopter des attitudes favorables notamment pour sa santé et son bien-être.",
    "textSectionRight1": "Dans un esprit bienveillant et ludique, une conseillère en image vous accompagne pour vous transmettre des conseils de mise en valeur de votre visage et de votre silhouette, vous aider à être attentif à vos besoins et vous encourager à vous affirmer. <br> Ces séances sont également l’occasion de décrypter les recommandations de santé et d’échanger sur la notion de bien-vieillir.",
    "textSectionRight2": "L’atelier « bien être : prendre soin de soi » se compose de 4 modules : ",
    "textSectionRight3": "Expression de soi et connaissance des autres.",
    "textSectionRight4": "Estime de soi, confiance en soi et affirmation de soi.",
    "textSectionRight5": "Communication non verbale et bien vieillir actif.",
    "textSectionRight6": "La colorimétrie et la mise en valeur du visage et de la silhouette.",
    "module": "Module ",
    "contactUs": "Contactez nous"
  }
}
</i18n>

<style scoped>

.textBlue {
  color: #57939E;
}

.textGrey {
  color: #808080;
}

.section {
  padding-left: 200px;
  padding-right: 200px;
}

.sectionMobile {
  font-size: 14px;
  padding-bottom: 20px;
}

.textSection {
  padding-right: 100px;
}

.contactUsBtn {
  margin-left: 50px;
  font-size: 18px;
  text-transform: none;
  width: 400px;
}

.contactUsBtnMobile {
  font-size: 16px;
  text-transform: none;
  width: 300px;
}
</style>
